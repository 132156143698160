/* eslint-disable no-script-url */
import React from 'react'

import * as style from './styles'

const TEXTS = {
  ohNo: 'أوه لا!',
  notSupposedToHappen: 'ليس من المفترض أن يحدث هذا.',
  maybeYouCanTry: 'ربما يمكنك المحاولة',
  refreshingThePage: 'تحديث الصفحة',
}

const Error = ({ statusCode }) => {
  return (
    <div css={style.errorPage}>
      <h3>{TEXTS.ohNo}</h3>
      <div css={style.statusCode}>{statusCode}</div>
      <p>
        {TEXTS.notSupposedToHappen}
        {TEXTS.maybeYouCanTry} <a href='javascript:window.location.reload(true)'>{TEXTS.refreshingThePage}</a>.
      </p>
    </div>
  )
}

Error.getInitialProps = ({ res, err }) => {
  let statusCode = 404

  if (res) {
    statusCode = res.statusCode
  } else if (err) {
    statusCode = err.statusCode
  }

  return { statusCode }
}

export default Error
