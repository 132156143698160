import { css } from '@emotion/react'

export const errorPage = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;

  a {
    text-decoration: underline;
  }
`

export const statusCode = css`
  font-size: 10rem;
  margin: 0;
`
